.cardImage{
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid black;
    border-radius: 5px;
}

.cardContent{
    display: flex;
    padding: 16px;
    flex-direction: column;
}

.textBoxStyles{

    border-radius: 8px;
    padding: 6px 12px;

}



.textBoxNumberCoopon{
    width: 260px;

}

.contentNumberCoopon{

    top: 10px;
    position: relative;
    left: 333px;

}

.contentDateTHCoopon{
    font-size: 28px;
    top: 15px;
    position: relative;
    left: 100px;
    color: #393C39;
}



.contentDateENCoopon{
    font-size: 18px;
    top: 5px;
    position: relative;
    left: 335px;
    color: red;
}

.textBoxDateENCoopon{
    height: 33px;
    width: 230px;
    font-size: 16px;
}

.contentNoCoopon{
    top: 55px;
    position: relative;
    left: 510px;
}

.textBoxNoCoopon{
    height: 48px;
    width: 75px;
    font-size: 25px;
}


/*.disPlaytxtNum1{*/
/*    color: #393C39;*/
/*    font-size: 30px;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 500;*/
/*    font-style: normal;*/
/*}*/

/*.disPlaytxtNum2{*/
/*    color: #393C39;*/
/*    font-size: 14px;*/
/*    margin-top: -10px;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/
.disPlaytxtNum1{
    color: #393C39;
    font-size: 36px;
    font-family: "LOT1";
    font-weight: 200;
    font-style: normal;

  
    opacity: 0.9;
 
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}
.disPlaytxtNum2{
    color: #393C39;
    font-size: 10px;
    margin-top: -10px;
    font-family:"LOT1";
    font-style: normal;
  
    opacity: 0.9;
    font-weight: 200;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}

.disPlaytxtNum3{
    color: #393C39;
    font-size: 30px;
    font-family: "LOT2";
    font-weight: 500;
    font-style: normal;
}
.disPlaytxtNum4{
    color: #393C39;
    font-size: 14px;
    margin-top: -10px;
    font-family:"LOT2";
    font-weight: 400;
    font-style: normal;
}





.disPlaytxtNumCoopon{
   padding-right: 19px;
   margin-top: 6px;
}

.disPlaytextNumberloop{
    display: flex;
    margin-left: 14px;
  }


