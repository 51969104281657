@font-face {
    font-family: 'M PLUS 1p';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mplus1p/v28/e3tmeuShHdiFyPFzBRrQDBcQfEnXgPRE4g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2isfFJXUdVNF.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'MaehongsonMedium';
    src: url('./font/PK_Maehongson/PK_Maehongson_Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Jibjibfont';
    src: url('./font/Jibjib/Jibjibfont-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'LOT1';
    src: url('./font/Lot1Font/Fonts_lottothai-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'LOT2';
    src: url('./font/Lot2Font/Font_thailotto-Regular.ttf') format('truetype');
}


@font-face {
    font-family: 'FONTV1';
    src: url('./font/Font/Fonts_lottothai-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'FONTV2';
    src: url('./font/Font_V2/Font_thailotto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'FONTV3';
    src: url('./font/V3/Font_thailotto_v3-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'FONTV4';
    src: url('./font/Fonts_lottothai-Regular.ttf') format('truetype');
}
.App {
    text-align: center;
    padding: 6px 12px;
}

.displayImg {
    padding: 16px;
}

.displayCard {
    padding: 16px;
}

.displayInput {
    padding: 16px;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.inputStyles {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 6px 12px;
    height: 38px;
    width: 120px;
}

.allCoopong {
    display: flex;

}



.cardCoopong {
    width: 447px !important;
    height: 227px !important;
    padding: 4px;
}

.txtNum1 {
    font-size: 22px;
    opacity: 0.9;
    /* font-family: "LOT1"; */
    font-weight: 200;
    font-style: normal;
    margin-bottom: 5px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);

}

.txtNum2 {
    color: #1c1d1c;
    font-size: 7px;
    opacity: 0.9;
    margin-top: -10px;
    /* font-family: "LOT1"; */
    font-weight: 200;
    font-style: normal;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}


/*.txtNum1 {*/
/*    color: #393C39;*/
/*    font-size: 73px;*/
/*    opacity: 0.8;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 500;*/
/*    font-style: normal;*/
/*}*/

/*.txtNum2 {*/
/*    color: #393C39;*/
/*    font-size: 25px;*/
/*    opacity: 0.8;*/
/*    margin-top: -30px;*/
/*    font-family: "MaehongsonMedium";*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/

.txtNumCoopon {
    padding-right: 8px;
    margin-left: 1px;
    padding-top: -5px;
}


.textNumberloop {
    padding-left: 4px;
    display: flex;
    margin-top: 0px;
}

.txtNum3 {
    color: #393C39;
    font-size: 10px;
    margin-top: -15px;
    padding-left: 640px;
}

.dateTitle {
    margin-top: -20px;
    width: 170px;
    display: flex;
    flex-direction: column;
}

.dateTitleThai {

    color: #393C39;
    font-size: 16px;
}

.dateTitleEng {

    color: red;
    font-size: 16px;
    margin-top: -5px;
}

.noCooponTitleloop {
    margin-top: 25px;
    width: 260px;
    color: #393C39;
    font-size: 24px;
}

.noCooponTitle {
    margin-top: 25px;
    width: 300px;
    color: #393C39;
    font-size: 24px;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
    /* .txtNum1 {
      font-size: 18px;
    }

    .txtNum2 {
        font-size: 10px;
    } */
    .textNumberloop {
        margin-top: 14px;
        padding-left: 15%;
    }
    
    .txtNumCoopon{
        margin-top: 3px;
        padding-right: 11px;

    }
  }

  @media screen and (max-width: 1400px) {
    /* .txtNum1 {
      font-size: 18px;
    } */

    /* .txtNum2 {
        font-size: 10px;
    } */
    .textNumberloop {
        margin-top: 14px;
        padding-left: 15%;
    }
    .txtNumCoopon{
        margin-top: 3px;
        padding-right: 11px;

    }
  }

  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {
    /* .txtNum1 {
      font-size: 18px;
    } */
  }


